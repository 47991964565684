define("discourse/plugins/discourse-events/discourse/components/events-message", ["exports", "@ember/component", "@ember/object/computed", "discourse-common/utils/decorators", "I18n", "@ember/template-factory"], function (_exports, _component, _computed, _decorators, _I18n, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="message-block primary">
    {{#if this.showIcon}}
      {{d-icon this.icon}}
    {{/if}}
    <span class="message-content">{{html-safe this.text}}</span>
    {{#if this.hasItems}}
      <ul>
        {{#each this.items as |item|}}
          <li>
            <span>{{d-icon item.icon}}</span>
            <span>{{html-safe item.html}}</span>
          </li>
        {{/each}}
      </ul>
    {{/if}}
  </div>
  
  {{#if this.showDocumentation}}
    <div class="message-block">
      {{d-icon "circle-question"}}
  
      <a href={{this.documentationUrl}} target="_blank" rel="noopener noreferrer">
        {{this.documentation}}
      </a>
    </div>
  {{/if}}
  */
  {
    "id": "7DlC866a",
    "block": "[[[10,0],[14,0,\"message-block primary\"],[12],[1,\"\\n\"],[41,[30,0,[\"showIcon\"]],[[[1,\"    \"],[1,[28,[35,1],[[30,0,[\"icon\"]]],null]],[1,\"\\n\"]],[]],null],[1,\"  \"],[10,1],[14,0,\"message-content\"],[12],[1,[28,[35,2],[[30,0,[\"text\"]]],null]],[13],[1,\"\\n\"],[41,[30,0,[\"hasItems\"]],[[[1,\"    \"],[10,\"ul\"],[12],[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[30,0,[\"items\"]]],null]],null],null,[[[1,\"        \"],[10,\"li\"],[12],[1,\"\\n          \"],[10,1],[12],[1,[28,[35,1],[[30,1,[\"icon\"]]],null]],[13],[1,\"\\n          \"],[10,1],[12],[1,[28,[35,2],[[30,1,[\"html\"]]],null]],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[1]],null],[1,\"    \"],[13],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\\n\"],[41,[30,0,[\"showDocumentation\"]],[[[1,\"  \"],[10,0],[14,0,\"message-block\"],[12],[1,\"\\n    \"],[1,[28,[35,1],[\"circle-question\"],null]],[1,\"\\n\\n    \"],[10,3],[15,6,[30,0,[\"documentationUrl\"]]],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[12],[1,\"\\n      \"],[1,[30,0,[\"documentation\"]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[\"item\"],false,[\"if\",\"d-icon\",\"html-safe\",\"each\",\"-track-array\"]]",
    "moduleName": "discourse/plugins/discourse-events/discourse/components/events-message.hbs",
    "isStrictMode": false
  });
  const icons = {
    error: "times-circle",
    success: "check-circle",
    warn: "exclamation-circle",
    info: "circle-info"
  };
  const urls = {
    provider: "https://discourse.angus.blog/c/events",
    source: "https://discourse.angus.blog/c/events",
    connection: "https://discourse.angus.blog/c/events",
    event: "https://discourse.angus.blog/c/events",
    log: "https://discourse.angus.blog/c/events"
  };
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend(dt7948.p({
    classNameBindings: [":events-message", "message.type", "loading"],
    showDocumentation: (0, _computed.not)("loading"),
    showIcon: (0, _computed.not)("loading"),
    hasItems: (0, _computed.notEmpty)("items"),
    icon(type) {
      return icons[type] || "circle-info";
    },
    text(key, viewName, opts) {
      return _I18n.default.t(`admin.events.message.${viewName}.${key}`, opts || {});
    },
    documentation() {
      return _I18n.default.t(`admin.events.message.documentation`);
    },
    documentationUrl(viewName) {
      return urls[viewName] || "https://discourse.angus.blog/c/events";
    }
  }, [["method", "icon", [(0, _decorators.default)("message.type")]], ["method", "text", [(0, _decorators.default)("message.key", "viewName", "message.opts")]], ["method", "documentation", [_decorators.default]], ["method", "documentationUrl", [(0, _decorators.default)("viewName")]]])));
});