define("discourse/plugins/discourse-events/discourse/components/events-subscription-tag", ["exports", "@glimmer/component", "@ember/string", "I18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _string, _I18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class EventsSubscriptionTag extends _component.default {
    get title() {
      return _I18n.default.t(`admin.events.subscription.tags.${this.args.subscription}.title`);
    }
    get class() {
      return `events-subscription-tag ${(0, _string.dasherize)(this.args.subscription)}`;
    }
    get label() {
      return _I18n.default.t(`admin.events.subscription.tags.${this.args.subscription}.label`);
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <span class={{this.class}} title={{this.title}}>
          {{this.label}}
        </span>
      
    */
    {
      "id": "/9guIU4g",
      "block": "[[[1,\"\\n    \"],[10,1],[15,0,[30,0,[\"class\"]]],[15,\"title\",[30,0,[\"title\"]]],[12],[1,\"\\n      \"],[1,[30,0,[\"label\"]]],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[],false,[]]",
      "moduleName": "/build/source/app/assets/javascripts/discourse/discourse/plugins/discourse-events/discourse/components/events-subscription-tag.js",
      "isStrictMode": true
    }), this))();
  }
  _exports.default = EventsSubscriptionTag;
});