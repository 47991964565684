define("discourse/plugins/discourse-events/discourse/components/events-subscription-badge", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "discourse/components/conditional-loading-spinner", "discourse/components/d-button", "discourse/lib/url", "I18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _object, _service, _conditionalLoadingSpinner, _dButton, _url, _I18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class EventsSubscriptionBadge extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "subscription", [(0, _service.service)("events-subscription")]))();
    #subscription = (() => (dt7948.i(this, "subscription"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "updating", [_tracking.tracked], function () {
      return false;
    }))();
    #updating = (() => (dt7948.i(this, "updating"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "updateIcon", [_tracking.tracked], function () {
      return "arrows-rotate";
    }))();
    #updateIcon = (() => (dt7948.i(this, "updateIcon"), void 0))();
    get i18nKey() {
      return `admin.events.subscription.type.${this.subscription.subscribed ? this.subscription.product : "none"}`;
    }
    get title() {
      return _I18n.default.t(`${this.i18nKey}.title`);
    }
    get label() {
      return _I18n.default.t(`${this.i18nKey}.label`);
    }
    get classes() {
      let classes1 = "btn-primary events-subscription-badge";
      if (this.subscription.subscribed) {
        classes1 += ` subscribed ${this.subscription.product}`;
      }
      return classes1;
    }
    click() {
      _url.default.routeTo(this.subscription.ctaPath);
    }
    static #_4 = (() => dt7948.n(this.prototype, "click", [_object.action]))();
    update() {
      this.updating = true;
      this.updateIcon = null;
      this.subscription.getSubscriptionStatus(true).finally(() => {
        this.updateIcon = "arrows-rotate";
        this.updating = false;
      });
    }
    static #_5 = (() => dt7948.n(this.prototype, "update", [_object.action]))();
    static #_6 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <DButton
          @icon={{this.updateIcon}}
          @action={{this.update}}
          class="btn update-subscription-status"
          @disabled={{this.updating}}
          @title="admin.events.subscription.update.title"
        >
          <ConditionalLoadingSpinner @condition={{this.updating}} @size="small" />
        </DButton>
        <DButton
          @action={{this.click}}
          class={{this.classes}}
          @translatedTitle={{this.title}}
          @translatedLabel={{this.label}}
        />
      
    */
    {
      "id": "+QIdk/JU",
      "block": "[[[1,\"\\n    \"],[8,[32,0],[[24,0,\"btn update-subscription-status\"]],[[\"@icon\",\"@action\",\"@disabled\",\"@title\"],[[30,0,[\"updateIcon\"]],[30,0,[\"update\"]],[30,0,[\"updating\"]],\"admin.events.subscription.update.title\"]],[[\"default\"],[[[[1,\"\\n      \"],[8,[32,1],null,[[\"@condition\",\"@size\"],[[30,0,[\"updating\"]],\"small\"]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n    \"],[8,[32,0],[[16,0,[30,0,[\"classes\"]]]],[[\"@action\",\"@translatedTitle\",\"@translatedLabel\"],[[30,0,[\"click\"]],[30,0,[\"title\"]],[30,0,[\"label\"]]]],null],[1,\"\\n  \"]],[],false,[]]",
      "moduleName": "/build/source/app/assets/javascripts/discourse/discourse/plugins/discourse-events/discourse/components/events-subscription-badge.js",
      "scope": () => [_dButton.default, _conditionalLoadingSpinner.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = EventsSubscriptionBadge;
});