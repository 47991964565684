define("discourse/plugins/discourse-events/discourse/components/events-calendar-body", ["exports", "@glimmer/component", "discourse/plugins/discourse-events/discourse/lib/date-utilities", "discourse/plugins/discourse-events/discourse/components/events-calendar-day", "@ember/component", "@ember/template-factory"], function (_exports, _component, _dateUtilities, _eventsCalendarDay, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class EventsCalendarBody extends _component.default {
    get weekdays() {
      let data1 = moment.localeData();
      let weekdays1 = this.args.responsive ? data1.weekdaysMin() : data1.weekdays();
      let firstDay1 = (0, _dateUtilities.firstDayOfWeek)();
      // Create a copy of the array before splicing to avoid modifying the original
      weekdays1 = [...weekdays1];
      // If firstDay is not 0 (Sunday), rotate the array
      if (firstDay1 > 0) {
        let beforeFirst1 = weekdays1.splice(0, firstDay1);
        weekdays1.push(...beforeFirst1);
      }
      return weekdays1;
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="events-calendar-body">
          {{#each this.weekdays as |weekday|}}
            <div class="weekday">
              <span>{{weekday}}</span>
            </div>
          {{/each}}
    
          {{#each @days as |day index|}}
            <EventsCalendarDay
              @day={{day}}
              @currentDate={{@currentDate}}
              @currentMonth={{@currentMonth}}
              @selectDate={{@selectDate}}
              @canSelectDate={{@canSelectDate}}
              @showEvents={{@showEvents}}
              @topics={{@topics}}
              @responsive={{@responsive}}
              @index={{index}}
            />
          {{/each}}
        </div>
      
    */
    {
      "id": "+Y9MRoas",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"events-calendar-body\"],[12],[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,0,[\"weekdays\"]]],null]],null],null,[[[1,\"        \"],[10,0],[14,0,\"weekday\"],[12],[1,\"\\n          \"],[10,1],[12],[1,[30,1]],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[1]],null],[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,2]],null]],null],null,[[[1,\"        \"],[8,[32,0],null,[[\"@day\",\"@currentDate\",\"@currentMonth\",\"@selectDate\",\"@canSelectDate\",\"@showEvents\",\"@topics\",\"@responsive\",\"@index\"],[[30,3],[30,5],[30,6],[30,7],[30,8],[30,9],[30,10],[30,11],[30,4]]],null],[1,\"\\n\"]],[3,4]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[\"weekday\",\"@days\",\"day\",\"index\",\"@currentDate\",\"@currentMonth\",\"@selectDate\",\"@canSelectDate\",\"@showEvents\",\"@topics\",\"@responsive\"],false,[\"each\",\"-track-array\"]]",
      "moduleName": "/build/source/app/assets/javascripts/discourse/discourse/plugins/discourse-events/discourse/components/events-calendar-body.js",
      "scope": () => [_eventsCalendarDay.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = EventsCalendarBody;
});