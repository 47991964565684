define("discourse/plugins/discourse-events/discourse/components/event-tag", ["exports", "@glimmer/component", "@ember/modifier", "@ember/object", "discourse/lib/url", "@ember/component", "@ember/template-factory"], function (_exports, _component, _modifier, _object, _url, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class EventTag extends _component.default {
    get class() {
      return `event-tag ${this.args.class}`;
    }
    click() {
      event?.preventDefault();
      event?.stopPropagation();
      if (this.args.href) {
        _url.default.routeTo(this.args.href);
      }
    }
    static #_ = (() => dt7948.n(this.prototype, "click", [_object.action]))();
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <a class={{this.class}} {{on "click" this.click}} role="button">
          <span>{{@label}}</span>
        </a>
      
    */
    {
      "id": "OUJvHh6l",
      "block": "[[[1,\"\\n    \"],[11,3],[16,0,[30,0,[\"class\"]]],[24,\"role\",\"button\"],[4,[32,0],[\"click\",[30,0,[\"click\"]]],null],[12],[1,\"\\n      \"],[10,1],[12],[1,[30,1]],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@label\"],false,[]]",
      "moduleName": "/build/source/app/assets/javascripts/discourse/discourse/plugins/discourse-events/discourse/components/event-tag.js",
      "scope": () => [_modifier.on],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = EventTag;
});